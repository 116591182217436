<template>
  <div class="ancvue-product-list phone" v-if="!$store.state.isPadHorizontal">
    <!-- 标题 -->
    <!-- 右眼无产品 -->
    <div v-if="status === 'Right'" class="description">
      <span>根据您的验光/框架度数换算，</span>
      <span class="description-red">
        您的右眼试戴参数为：{{ noProdEyePa.sphereRight }}/{{
          noProdEyePa.cylinderRight
        }}*{{ noProdEyePa.axisRight }}，当前无强生安视优适合产品推荐。
      </span>
      <span class="second-line" v-if="whitchEyeHasValue === 'all'">
        您的左眼有如下产品可试戴，请您先挑选其中一种。
      </span>
    </div>

    <!-- 左眼无产品 -->
    <div v-if="status === 'Left'" class="description">
      <span>根据您的验光/框架度数换算，</span>
      <span class="description-red">
        您的左眼试戴参数为：{{ noProdEyePa.sphereLeft }}/{{ noProdEyePa.cylinderLeft }}*{{
          noProdEyePa.axisLeft
        }}，当前无强生安视优适合产品推荐。
      </span>
      <span class="second-line" v-if="whitchEyeHasValue === 'all'">
        您的右眼有如下产品可试戴，请您先挑选其中一种。
      </span>
    </div>

    <!-- 两只眼睛都有产品 -->
    <div class="description" v-if="status === null">
      <div v-if="whitchEyeHasValue === 'right'">
        <span class="description-red">您的左眼没有匹配的产品。</span>
        <div class="second-line">您的右眼有如下产品可试戴，请选择：</div>
      </div>
      <div v-else-if="whitchEyeHasValue === 'left'">
        <span class="description-red">您的右眼没有匹配的产品。</span>
        <span class="second-line">您的左眼有如下产品可试戴，请选择：</span>
      </div>
      <div v-else>根据输入的处方发现下列可选用的产品，请选择：</div>
    </div>

    <!-- 产品区域 -->
    <div v-if="!loading">
      <div class="product-list-wrapper">
        <div class="body">
          <div v-if="upperList.length">
            <div class="description">{{ upperTitle }}</div>
            <div class="product-list">
              <div
                class="product-item"
                v-for="(item, index) in upperList"
                :key="index"
                @click="onClick(item)"
              >
                <div
                  class="product-bg"
                  :class="{
                    'is-selected': upperSelectProduct?.productId === item.productId,
                  }"
                >
                  <img :src="item.productUrl" />
                </div>
                <div class="product-name">{{ item.productName }}</div>
              </div>
              <div class="blank"></div>
            </div>
          </div>
          <div v-if="lowerList.length">
            <div class="description">{{ lowerTitle }}</div>
            <div class="product-list">
              <div
                class="product-item"
                v-for="(item, index) in lowerList"
                :key="index"
                @click="onClick(item)"
              >
                <div
                  class="product-bg"
                  :class="{
                    'is-selected': lowerSelectProduct?.productId === item.productId,
                  }"
                >
                  <img :src="item.productUrl" />
                </div>
                <div class="product-name">{{ item.productName }}</div>
              </div>
              <div class="blank"></div>
            </div>
          </div>
          <div
            v-if="!loading && upperList.length == 0 && lowerList.length == 0"
            class="no-data"
          >
            无匹配产品可提供，建议调整。
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="no-data">加载中...</div>
    <!-- 下一步按钮 -->
    <div class="next-button" @click="goNext">
      <span class="next-button-text">下一步</span>
    </div>
    <!-- 返回按钮 -->
    <div class="calculate-button" @click="goBack">
      <img class="arrow" src="@/assets/imgs/left-arrow.png" />
      <span class="calculate-button-text">返回</span>
    </div>
  </div>

  <!-- ipad -->
  <div class="ancvue-product-list pad-horizontal" v-if="$store.state.isPadHorizontal">
    <!-- 标题 -->
    <div class="description">
      <!-- 右眼无产品 -->
      <div v-if="status === 'Right'" class="description-half">
        <span>根据您的验光/框架度数换算，</span>
        <span class="description-red">
          您的右眼试戴参数为：{{ noProdEyePa.sphereRight }}/{{
            noProdEyePa.cylinderRight
          }}*{{ noProdEyePa.axisRight }}，当前无强生安视优适合产品推荐。
        </span>
        <span class="second-line" v-if="whitchEyeHasValue === 'all'">
          您的左眼有如下产品可试戴，请您先挑选其中一种。
        </span>
      </div>
      <!-- 左眼无产品 -->
      <div v-if="status === 'Left'" class="description-half">
        <span>根据您的验光/框架度数换算，</span>
        <span class="description-red">
          您的左眼试戴参数为：{{ noProdEyePa.sphereLeft }}/{{
            noProdEyePa.cylinderLeft
          }}*{{ noProdEyePa.axisLeft }}，当前无强生安视优适合产品推荐。
        </span>
        <span class="second-line" v-if="whitchEyeHasValue === 'all'">
          您的右眼有如下产品可试戴，请您先挑选其中一种。
        </span>
      </div>
      <!-- 两只眼睛都有产品 -->
      <div class="description-half" v-if="status === null">
        <div v-if="whitchEyeHasValue === 'right'">
          <span class="description-red">您的左眼没有匹配的产品。</span>
          <div class="second-line">您的右眼有如下产品可试戴，请选择：</div>
        </div>
        <div v-else-if="whitchEyeHasValue === 'left'">
          <span class="description-red">您的右眼没有匹配的产品。</span>
          <span class="second-line">您的左眼有如下产品可试戴，请选择：</span>
        </div>
        <div v-else>根据输入的处方发现下列可选用的产品，请选择：</div>
      </div>

      <!-- 返回按钮 -->
      <div class="calculate-button" @click="goBack">
        <img class="arrow" src="@/assets/imgs/left-arrow.png" />
        <span class="calculate-button-text">返回</span>
      </div>
      <!-- 确认按钮 -->
      <div class="next-button" @click="goNext">
        <span class="next-button-text">下一步</span>
      </div>
    </div>
    <div v-if="!loading" class="products-wrapper">
      <div class="body">
        <!-- 日抛 周期抛 都存在 -->
        <template v-if="upperList.length && lowerList.length">
          <!-- 左侧 -->
          <div class="products-wrapper-item-wrapper">
            <div class="products-wrapper-item left">
              <div class="description">{{ upperTitle }}</div>
              <div ref="leftSwiperRef">
                <van-swipe class="my-swipe" indicator-color="#00a2df">
                  <van-swipe-item
                    v-for="(item, index) in formatPadList(upperList)"
                    :key="index"
                  >
                    <div class="product-list product-list-pad">
                      <div
                        v-for="subItem in item"
                        :key="subItem"
                        class="product-item right-margin"
                        @click="onClick(subItem)"
                      >
                        <div
                          class="product-bg"
                          :class="{
                            'is-selected':
                              upperSelectProduct?.productId === subItem.productId,
                          }"
                        >
                          <img :src="subItem.productUrl" />
                        </div>
                        <div class="product-name">{{ subItem.productName }}</div>
                      </div>
                    </div>
                  </van-swipe-item>
                </van-swipe>
              </div>
            </div>
          </div>
          <!-- 右侧 -->
          <div class="products-wrapper-item-wrapper">
            <div class="products-wrapper-item right">
              <div class="description">{{ lowerTitle }}</div>
              <div ref="rightSwiperRef">
                <van-swipe class="my-swipe" indicator-color="#00a2df">
                  <van-swipe-item
                    v-for="(item, index) in formatPadList(lowerList)"
                    :key="index"
                  >
                    <div class="product-list product-list-pad">
                      <div
                        v-for="subItem in item"
                        :key="subItem"
                        class="product-item right-margin"
                        @click="onClick(subItem)"
                      >
                        <div
                          class="product-bg"
                          :class="{
                            'is-selected':
                              lowerSelectProduct?.productId === subItem.productId,
                          }"
                        >
                          <img :src="subItem.productUrl" />
                        </div>
                        <div class="product-name">
                          {{ subItem.productName }}
                        </div>
                      </div>
                    </div>
                  </van-swipe-item>
                </van-swipe>
              </div>
            </div>
          </div>
        </template>
        <!-- 只存在日抛 -->
        <div v-if="upperList.length && !lowerList.length" style="width: 100%">
          <div class="description">{{ upperTitle }}</div>
          <div class="product-list-scroll">
            <div
              v-for="item in upperList"
              :key="item"
              class="product-item-scroll"
              @click="onClick(item)"
            >
              <div
                class="product-bg"
                :class="{
                  'is-selected': upperSelectProduct?.productId === item.productId,
                }"
              >
                <img :src="item.productUrl" />
              </div>
              <div class="product-name">{{ item.productName }}</div>
            </div>
          </div>
        </div>
        <!-- 只存在周期抛 -->
        <div v-if="!upperList.length && lowerList.length" style="width: 100%">
          <div class="description">{{ lowerTitle }}</div>
          <div class="product-list-scroll">
            <div
              v-for="item in lowerList"
              :key="item"
              class="product-item-scroll"
              @click="onClick(item)"
            >
              <div
                class="product-bg"
                :class="{
                  'is-selected': lowerSelectProduct?.productId === item.productId,
                }"
              >
                <img :src="item.productUrl" />
              </div>
              <div class="product-name">{{ item.productName }}</div>
            </div>
          </div>
        </div>
        <div
          v-if="!loading && upperList.length == 0 && lowerList.length == 0"
          class="no-data"
        >
          无匹配产品可提供，建议调整。
        </div>
      </div>
    </div>
    <div v-if="loading" class="no-data">加载中...</div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, reactive, toRaw, watchEffect } from "vue";
import axios from "axios";
import { showNotify } from "vant";
import { useRouter } from "vue-router";

const props = defineProps({});
const emits = defineEmits([]);
const router = useRouter();

let whitchEyeHasValue = window.localStorage.getItem("whitchEyeHasValue");

// null: 两只眼睛都有产品。Left: 左眼没有产品，右眼有产品。Right: 右眼没有产品，左眼有产品
const status = ref(null);
// 没有产品的眼睛的球镜柱镜轴位参数
const noProdEyePa = ref({});
const upperTitle = ref("");
const lowerTitle = ref("");
const upperList = ref([]);
const lowerList = ref([]);
// 当两只眼睛都有产品时，当前选中的产品
const upperSelectProduct = ref({});
const lowerSelectProduct = ref({});
const leftSwiperRef = ref(null);
const rightSwiperRef = ref(null);
const loading = ref(false);

const formatPadList = list => {
  const arr = [];
  for (let i = 0; i < Math.ceil(list.length / 2); i++) {
    const subArr = [];
    for (let j = i * 2; j < (i + 1) * 2; j++) {
      list[j] && subArr.push(list[j]);
    }
    arr.push(subArr);
  }
  return arr;
};

const onClick = item => {
  if (
    status.value === "Left" ||
    status.value === "Right" ||
    whitchEyeHasValue === "right" ||
    whitchEyeHasValue === "left"
  ) {
    console.log(item);
    // 如果只有一只眼睛有产品
    if (item.typeNameUp == "日抛") {
      if (item.modality == "Daily") {
        upperSelectProduct.value = item;
        lowerSelectProduct.value = null;
      } else {
        upperSelectProduct.value = null;
        lowerSelectProduct.value = item;
      }
    } else {
      if (item.isAstig) {
        upperSelectProduct.value = item;
        lowerSelectProduct.value = null;
      } else {
        lowerSelectProduct.value = item;
        upperSelectProduct.value = null;
      }
    }
  } else if (status.value === null) {
    // 如果两只眼睛都有产品
    // 只有散光非散光都有的时候才可以选两个产品
    console.log("productInfo", item);
    if (item.typeNameUp == "日抛") {
      if (item.modality == "Daily") {
        upperSelectProduct.value = item;
        lowerSelectProduct.value = null;
      } else {
        upperSelectProduct.value = null;
        lowerSelectProduct.value = item;
      }
    } else {
      if (item.isAstig) {
        upperSelectProduct.value = item;
      } else {
        lowerSelectProduct.value = item;
      }
    }
  }

  let productList = [];
  if (upperSelectProduct.value) productList.push(upperSelectProduct.value);
  if (lowerSelectProduct.value) productList.push(lowerSelectProduct.value);

  let productIdList = [];
  if (upperSelectProduct.value) productIdList.push(upperSelectProduct.value.productId);
  if (lowerSelectProduct.value) productIdList.push(lowerSelectProduct.value.productId);

  window.sessionStorage.setItem("productInfo", JSON.stringify(productList));
  window.sessionStorage.setItem("selectedProductId", JSON.stringify(productIdList)); // 设置选择的产品id，在返回首页时清零
};

const goNext = () => {
  const selectedProductList = JSON.parse(window.sessionStorage.getItem("productInfo"));
  console.log(selectedProductList);
  if (!selectedProductList[0]?.productId && !selectedProductList[1]?.productId) {
    return showNotify({
      message: "请您选择使用的产品后，再点击下一步",
      background: "#dc4a3a",
    });
  }
  console.log(111111111);
  // 只有散光非散光需要选两个产品
  console.log(
    upperTitle.value != "日抛" && upperList.value.length && lowerList.value.length
  );
  if (upperTitle.value != "日抛" && upperList.value.length && lowerList.value.length) {
    console.log(selectedProductList);
    if (!selectedProductList[0].productId || !selectedProductList[1].productId) {
      return showNotify({
        message: "请您分别选择2只眼睛使用的产品后，再点击下一步",
        background: "#dc4a3a",
      });
    }
  }
  router.push({ name: "ProductDetail" });
};

const goBack = () => {
  router.push({ name: "Home" });
};

const sortBySort = arr => {
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr.length - i - 1; j++) {
      let item = arr;
      let temp;
      if (item[j].sort > item[j + 1].sort) {
        temp = item[j];
        item[j] = item[j + 1];
        item[j + 1] = temp;
      }
    }
  }
  return arr;
};

onMounted(() => {
  loading.value = true;

  const obj = JSON.parse(window.sessionStorage.getItem("prescription"));
  const leftFixAxisDeg = JSON.parse(window.sessionStorage.getItem("leftFixAxisDeg"));
  const rightFixAxisDeg = JSON.parse(window.sessionStorage.getItem("rightFixAxisDeg"));

  axios({
    url: "/api/widgets/query_product_by_sku?authcode=CN20230209ASD43ADSCADZ",
    method: "post",
    data: {
      sphereLeft: obj.leftSphere,
      sphereRight: obj.rightSphere,
      cylinderLeft: obj.leftCylinder || 0,
      cylinderRight: obj.rightCylinder || 0,
      axisLeft: obj.leftAxis || 0,
      axisRight: obj.rightAxis || 0,
      productId: JSON.parse(window.sessionStorage.getItem("selectedProductId")),
      lensDeflectionLeft: leftFixAxisDeg || 0,
      lensDeflectionRight: rightFixAxisDeg || 0,
    },
  })
    .then(res => {
      let data = res.data.data;
      if (data.length) {
        // 如果产品列表有长度，说明至少有一只眼睛有产品。
        // 如果所有产品的 eye 属性都是 null，说明两只眼睛都有产品。同时将 status 设置为 null。
        // 如果有产品的 eye 属性是 Left 或者 Right，代表对应眼睛没有产品。同时将 status 设置为 Left 或 Right
        status.value = null;
        data.forEach(item => {
          if (item.productId === 0) {
            status.value = item.eye;
            noProdEyePa.value = item;
          }
        });
        data = data.filter(item => item.productId !== 0);
        // 上面的标题【日抛、散光】
        upperTitle.value = data[0].typeNameUp;
        // 下面的标题【周期抛、非散光】
        lowerTitle.value = data[0].typeNameDown;
        // 【日抛/周期抛】根据产品中的 modality 判断是否为日抛产品，Daily 为日抛，Monthly 为周期抛
        if (upperTitle.value == "日抛") {
          upperList.value = data.filter(item => item.modality == "Daily");
          lowerList.value = data.filter(item => item.modality != "Daily");
        } else {
          // 【散光/非散光】根据产品中的 isAstig 判断是否为散光产品，true 为散光，false 为非散光
          upperList.value = data.filter(item => item.isAstig);
          lowerList.value = data.filter(item => !item.isAstig);
        }
        // 将 upperList 和 lowerList 按照 sortNo 冒泡排序
        upperList.value = sortBySort(upperList.value);
        lowerList.value = sortBySort(lowerList.value);
      }

      // 从产品详情页返回后，初始化选中产品
      upperSelectProduct.value = {};
      lowerSelectProduct.value = {};
      loading.value = false;
    })
    .catch(() => {
      loading.value = false;
    });
});
</script>

<style lang="scss" scoped>
@import "@/style/product-list/phone.scss";
@import "@/style/product-list/pad.scss";
</style>
